import { useState } from "react";

export function Tooltip(props: ITooltipProps) {

    const [isShown, setIsShown] = useState(false);

    return (
        <div className={"info " + (isShown ? "open" : "")}
            onMouseEnter={() => {setIsShown(true)}}
            onMouseLeave={() => setIsShown(false)}>
            <div className="infotext" dangerouslySetInnerHTML={{__html: props.tooltip}}>
                
            </div>
        </div>
    )
}

export interface ITooltipProps {
    tooltip: string
}