import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BulkDownloadsApi, CaseStudiesApi, CaseStudiesApiFactory, CaseStudyInfo, CaseStudyInfoPagedResult, CaseStudyOrder, CountryInfo, RegionInfo, Role, SavedSearchInfo, SearchCaseStudyColumns, SearchCaseStudyRequest, TagValueInfo, useAuthContext } from "@pull/pull-groupm-csp-api";
import '../../assets/css/slick.css'
import '../../assets/css/slick-theme.css'
import { LoadingComponent } from "../../modules/core/LoadingComponent";
import { useGlobalContext } from "../../modules/core/GlobalContext";
import Select from 'react-select'
import { useForm, Controller } from "react-hook-form";
import { CaseStudyCard } from "../../modules/case-studies/CaseStudyCard";
import { useUserContext } from "../../modules/user/UserContext";
import { CaseStudyHelpers } from "../../helpers/caseStudyHelpers";
import { Helmet } from "react-helmet";

export function CaseStudySearch() {

    const [searchParams] = useSearchParams();
    var goodGrowth = searchParams.get('goodGrowth') == "true"
    var orderParam = searchParams.get('order') ?? "";
    const initialState = useLocation().state as ISearchFormProps;
    const { getApiConfiguration } = useAuthContext()
    const { addCaseStudyToBulkDownloads } = useUserContext();
    var { tags, regions, countries, awardCategories } = useGlobalContext()
    var [openFilters, setOpenFilters] = useState(false)
    var [loading, setLoading] = useState(false)
    var [addedBulk, setAddedBulk] = useState(false)
    var [isEmptyFilters, setEmptyFilters] = useState(true)
    var [isSavedSearch, setIsSavedSearch] = useState(false)
    var [isError, setIsError] = useState(false)
    var [results, setResults] = useState<CaseStudyInfoPagedResult | null>(null)
    var [order, setOrder] = useState<string>(CaseStudyOrder.Recent.toString())


    const defaultInitialValues = initialState ?? {
        searchText: "",
        advertisers: [],
        channels: [],
        objectives: [],
        sectors: [],
        audiences: [],
        tools: [],
        categories: [],
        countries: [],
        regions: [],
        awardsCategories: [],
        awardWinning: false,
        goodGrowth: false,
        page: 1,
        order: CaseStudyHelpers.parseOrderEnum(order),
        columns: null
    } as ISearchFormProps

    const getTagOptions = (options: TagValueInfo[] | undefined) => {
        return options?.map(ad => { return { label: ad.value, value: ad.id } }) ?? [];
    }

    const getLocaleOptions = (options: CountryInfo[] | RegionInfo[]) => {
        return options?.map(ad => { return { label: ad.name, value: ad.id } }) ?? [];
    }

    const options = {
        advertisers: getTagOptions(tags?.advertisers?.values),
        channels: getTagOptions(tags?.channels?.values),
        objectives: getTagOptions(tags?.objectives?.values),
        sectors: getTagOptions(tags?.sectors?.values),
        audiences: getTagOptions(tags?.audiences?.values),
        tools: getTagOptions(tags?.tools?.values),
        categories: getTagOptions(tags?.categories?.values),
        countries: getLocaleOptions(countries),
        regions: getLocaleOptions(regions),
        awardsCategories: awardCategories.map(ac => { return { label: ac.name, value: ac.id } }),
        order: Object.values(CaseStudyOrder).map(c => c as CaseStudyOrder)
    }

    useEffect(() => {
        (async () => {

            if (initialState)
                await search(initialState)
            else {
                var preSearch = goodGrowth || orderParam
                if (goodGrowth) {
                    setValue("goodGrowth", true)
                    defaultInitialValues.goodGrowth = true
                }
                if (orderParam) {
                    var orderEnum = CaseStudyHelpers.parseOrderEnum(orderParam)
                    setValue("order", orderEnum)
                    setOrder(orderParam)
                    defaultInitialValues.order = orderEnum;
                }
                if (preSearch) {
                    await search(defaultInitialValues)
                }
            }
        })();
    }, [tags, countries, regions, awardCategories])

    const { register, handleSubmit, reset, control, setValue, getValues } = useForm({ defaultValues: defaultInitialValues });

    const search = async (data: ISearchFormProps) => {
        setLoading(true)

        try {
            var model = formToModel(data)
            var caseStudiesApi = new CaseStudiesApi(getApiConfiguration())
            setResults(await caseStudiesApi.apiCaseStudiesSearchPost(model));
            setEmptyFilters(searchFiltersEmpty(data))
            setIsError(false)
        }
        catch {
            setIsError(true)
        }
        finally {
            setLoading(false)
            setAddedBulk(false)
            setIsSavedSearch(false)
        }
    }

    const formToModel = (data: ISearchFormProps) => {
        return {
            page: data?.page,
            pageSize: 12,
            selectedCountries: data?.countries ?? [],
            selectedRegions: data?.regions ?? [],
            goodGrowthOnly: data?.goodGrowth ?? false,
            awardWinningOnly: data?.awardWinning ?? false,
            keywords: data?.searchText ?? "",
            selectedAdvertisers: data?.advertisers ?? [],
            selectedTags: (data?.audiences ?? []).concat(data?.sectors ?? []).concat(data?.channels ?? []).concat(data?.objectives ?? []).concat(data?.tools ?? []).concat(data?.categories ?? []),
            selectedAwardCategories: data?.awardsCategories ?? [],
            order: data.order,
            columns: {
                includeImages: true,
                includeBrand: true,
                includeCountries: true,
                includeSubmissions: true
            }
        }
    }

    const searchFiltersEmpty = (data: ISearchFormProps) => {
        return !data.searchText && data.countries?.length == 0 && data.regions?.length == 0 && data.advertisers?.length == 0 && data.channels?.length == 0 && data.sectors?.length == 0
            && data.objectives?.length == 0 && data.audiences?.length == 0 && data.tools?.length == 0 && data.categories?.length == 0 && data.awardsCategories?.length == 0 && !data.goodGrowth && !data.awardWinning
    }

    const onSubmit = async (data: ISearchFormProps) => {
        await search(data)
    }

    const getMaxPage = () => {
        if (!results || !results.totalCount || !results.pageSize)
            return 1;
        return Math.ceil(results.totalCount / results.pageSize)
    }

    const addToBulkDownloads = async (e: React.MouseEvent) => {
        e.preventDefault()
        debugger;
        await addCaseStudyToBulkDownloads(results?.results?.map(cs => cs.id ?? 0) ?? [])
        setAddedBulk(true)
    }

    const saveWatchlist = async (e: React.MouseEvent) => {
        e.preventDefault();
        var formValues = formToModel(getValues())
        var caseStudiesApi = new CaseStudiesApi(getApiConfiguration())
        await caseStudiesApi.apiCaseStudiesSavedSearchPost(formValues)
        setIsSavedSearch(true)
    }

    const changeSort = async (e: React.ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault()
        var newOrder = CaseStudyHelpers.parseOrderEnum(e.currentTarget.value)
        setOrder(e.currentTarget.value)
        setValue("order", newOrder)
        search(getValues())
    }

    return (
        <>
            <Helmet>
                <title>Search</title>
            </Helmet>
            <form onSubmit={handleSubmit(onSubmit)}>
                <section className="search">
                    <div className="colour-tertiary clearfix">
                        <div className="container">
                            <h2>Case Study Search</h2>
                        </div>
                    </div>
                    <div className="colour-primary clearfix">
                        <div className="container search-field-container">
                            <input {...register('searchText')} type="text" placeholder="Search keywords..." />
                            <input type="submit" value="Search" onClick={() => setValue("page", 1)} />
                            <a href="#" id="advancedtoggle" className={openFilters ? "open" : ""} onClick={() => setOpenFilters(!openFilters)}></a>
                        </div>
                    </div>
                    <div className={"colour-secondary" + (openFilters ? " open" : "")} id="advancedsearch">
                        <div className="container infopanel">
                            <p className="subheading">Filter by:</p>

                            <div className="form clearfix">
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="advertisers"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.advertisers.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.advertisers}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Advertisers"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="awardsCategories"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.awardsCategories.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.awardsCategories}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Awards"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="countries"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.countries.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.countries}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Countries"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form clearfix">
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="regions"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.regions.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.regions}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Regions"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="channels"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.channels.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.channels}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Channels"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="audiences"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.audiences.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.audiences}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Audiences"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form clearfix">
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="sectors"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.sectors.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.sectors}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Sectors"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="objectives"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.objectives.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.objectives}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Objectives"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="tools"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.tools.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.tools}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Proprietary Products Used"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form clearfix">
                                <div className="threecol left">
                                    <Controller
                                        control={control}
                                        name="categories"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <Select
                                                    className={"react-select-container"}
                                                    classNamePrefix={"react-select"}
                                                    value={options.categories.filter(c => value?.includes(c.value ?? 0))}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                    options={options.categories}
                                                    onChange={val => onChange(val.map(c => c.value))}
                                                    placeholder="Category of Work"
                                                    maxMenuHeight={1000}
                                                    isMulti
                                                />
                                            )
                                        }}
                                    />
                                </div>
                                <div className="threecol left">
                                    <div className="checkbox">
                                        <label>Search award winning Case Studies Only
                                            <input type="checkbox" {...register("awardWinning")} />
                                        </label>
                                    </div>
                                </div>
                                <div className="threecol left">

                                </div>
                            </div>

                            {/* <div className="form clearfix">
                                <div className="threecol left">

                                    <div className="checkbox">
                                        <label>Good Growth Only
                                            <input type="checkbox" {...register("goodGrowth")} />
                                        </label>
                                    </div>
                                </div>
                                <div className="threecol left">
                                </div>
                                <div className="threecol left">
                                </div>
                            </div> */}
                            <div className="form clearfix">
                                <div className="threecol left">
                                    &nbsp;
                                </div>
                                <div className="threecol left">
                                    <a href="#" onClick={() => { reset() }} className="reset-link">Reset filters</a>
                                </div>
                                <div className="threecol right">
                                    <input type="submit" onClick={() => setValue("page", 1)} value="Search" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {
                    !loading ?
                        <>
                            <>
                                {results && !isError &&
                                    <div className="colour-secondary">
                                        <div className="container">
                                            <div className="searchtoggle clearfix">
                                                <p className="subheading left">{results.totalCount} Search Results</p>
                                                <p className="subheading left">
                                                    Sort by:
                                                    <select value={order} onChange={(e) => changeSort(e)}>
                                                        <option value="recent">Recent</option>
                                                        <option value="relevance">Relevance</option>
                                                        <option value="viewed">Viewed</option>
                                                        <option value="downloaded">Downloaded</option>
                                                    </select>
                                                </p>
                                                <div className="right relative search-actions">
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add page to Bulk Downloads
                                                    <a className={"search-bulk " + (addedBulk ? "active" : "")} href="#" onClick={(e) => addToBulkDownloads(e)}>&nbsp;</a>
                                                </div>
                                                {!initialState && !isEmptyFilters &&
                                                    <p className="subheading right watchlist-container">
                                                        <a onClick={(e) => saveWatchlist(e)} href="#" className="save-to-watchlist" data-saved={isSavedSearch} >
                                                            <div className="initial-state">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="butt" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                                                Save Search to Watchlist
                                                            </div>
                                                            <div className="saved-state">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="butt" stroke-linejoin="arcs"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                                Saved to Watchlist
                                                            </div>
                                                        </a>
                                                    </p>}
                                            </div>
                                        </div>
                                    </div>}
                                <>
                                    <div className="container bodycopy">
                                        <div className="searchresults clearfix list">
                                            {!results && <p>You can use the search box and filters above to search for case studies</p>}
                                            {!isError &&
                                                <>
                                                    {results && results.results?.map(cs => {
                                                        return <CaseStudyCard key={cs.id} caseStudy={cs} />
                                                    })}
                                                    {results && results.totalCount == 0 ?
                                                        <p>There were no results for your selected search</p> : ""}
                                                </>
                                            }
                                            {isError &&
                                                <div className="alert error">
                                                    <p>An error has occurred while performing your search request. Please try again later.</p>
                                                </div>
                                            }
                                        </div>
                                        {!isError &&
                                            <div className="pagination">
                                                {results && (results.totalCount ?? 0) > 0 && (results.page ?? 1) > 1 ?
                                                    <button className="button" onClick={() => setValue("page", (results?.page ?? 1) - 1)}>« Newer</button> : ""}
                                                &nbsp;&nbsp;
                                                {results && results.page && (results.totalCount ?? 0) > 0 && (results.page ?? 1) < getMaxPage() ?
                                                    <button className="button" onClick={() => setValue("page", (results?.page ?? 1) + 1)}>Older »</button> : ""}
                                            </div>
                                        }
                                    </div>
                                </>
                            </>
                        </> : <LoadingComponent height="300px" loadingAnimationDelay={0}></LoadingComponent>
                }
            </form >


        </>
    )

}

interface searchState {
    values: SearchCaseStudyRequest
}

export interface ISearchFormProps {
    searchText: string;
    advertisers: Array<number> | null;
    channels: Array<number> | null;
    objectives: Array<number> | null;
    sectors: Array<number> | null;
    audiences: Array<number> | null;
    tools: Array<number> | null;
    categories: Array<number> | null;
    countries: Array<number> | null;
    regions: Array<number> | null;
    awardsCategories: Array<number> | null;
    awardWinning: boolean;
    goodGrowth: boolean;
    page: number,
    order: CaseStudyOrder,
    columns: SearchCaseStudyColumns | null
};