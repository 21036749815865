
export class UserRoles{
    static readonly Administrator = "Administrator"
    static readonly Judge = "Judge"
}

export class Settings{
    static readonly CaseStudyTitleTooltip = "CaseStudyTitleTooltip"
    static readonly CaseStudyChallengeTooltip = "CaseStudyChallengeTooltip"
    static readonly CaseStudyIdeaTooltip = "CaseStudyIdeaTooltip"
    static readonly CaseStudyInsightTooltip = "CaseStudyInsightTooltip"
    static readonly CaseStudyDeliveryTooltip = "CaseStudyDeliveryTooltip"
    static readonly CaseStudyResultsTooltip = "CaseStudyResultsTooltip"
    static readonly CaseStudySummaryTooltip = "CaseStudySummaryTooltip"

    static readonly CaseStudyAdvertiserTooltip = "CaseStudyAdvertiserTooltip"
    static readonly CaseStudyBrandTooltip = "CaseStudyBrandTooltip"
    static readonly CaseStudyRegionTooltip = "CaseStudyRegionTooltip"
    static readonly CaseStudyCountryTooltip = "CaseStudyCountryTooltip"
    static readonly CaseStudyImplementationDateTooltip = "CaseStudyImplementationDateTooltip"
    static readonly CaseStudySectorTooltip = "CaseStudySectorTooltip"
    static readonly CaseStudyChannelTooltip = "CaseStudyChannelTooltip"
    static readonly CaseStudyObjectiveTooltip = "CaseStudyObjectiveTooltip"
    static readonly CaseStudyAudienceTooltip = "CaseStudyAudienceTooltip"
    static readonly CaseStudyToolsTooltip = "CaseStudyToolsTooltip"
    static readonly CaseStudyCategoriesTooltip = "CaseStudyCategoriesTooltip"
    static readonly CaseStudyTeamTooltip = "CaseStudyTeamTooltip"
    static readonly CaseStudyGoodGrowthTooltip = "CaseStudyGoodGrowthTooltip"

    static readonly CaseStudyCategoriesDescription = "CaseStudyCategoriesDescription"

    static readonly CaseStudyImageDescriptionTooltip = "CaseStudyImageDescriptionTooltip"
    static readonly CaseStudyImageOrderTooltip = "CaseStudyImageOrderTooltip"
}

export class Queries{
    static readonly InitialSetup = "initialSetup";
    static readonly CaseStudy = "caseStudy";
    static readonly UserWatchlists = "userWatchlists";
    static readonly UserWatchlistActivity = "userWatchlistActivity";
    static readonly UserActiveCollection = "userActiveCollections";
    static readonly UserCollections = "userCollections";
    static readonly AwardCats = "awardCats";
    static readonly AwardWinners = "awardWinners";
    static readonly AwardHeroWinners = "awardHeroWinners";
    static readonly AwardEntries = "awardEntries";

    static readonly AwardJudging = "awardJudging";

    static readonly News = "news";
    static readonly LatestNewsArticles = "latestNewsArticles";
    static readonly Page = "page";

    static readonly OpenAwards = "openAwards";
}

export class Urls{
    static readonly CaseStudiesCreate = "/casestudies/new";
    static readonly UserCaseStudies = "/user/casestudies/live"
    static readonly UserCaseStudiesDraft = "/user/casestudies/draft"
    static readonly UserBulkDownloads = "/user/bulkdownload/";
    static readonly UserBulkDownloadsHistory = "/user/bulkdownload/availabledownloads";
    static readonly UserWatchlist = "/user/watchlist"
    static readonly UserLogin = "/auth"
    static readonly UserLogout = "/auth/logout"
    static readonly NotFound = "/not-found"
}