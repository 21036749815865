import { CaseStudiesApi, CaseStudyInfo, Configuration, TagCollectionInfo, TagInfo, TagsApi, CountryInfo, SettingsApi, TagValueInfo } from '@pull/pull-groupm-csp-api'
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from "@pull/pull-groupm-csp-api";
import { CaseStudyStep } from "../../../modules/case-studies/CaseStudyProgress";
import { Formik } from 'formik';
import { useEffect, useRef, useState } from "react";
import 'materialize-css';
import $ from 'jquery'
import * as Yup from 'yup'
import { CaseStudyHelpers } from "../../../helpers/caseStudyHelpers";
import { IEditCaseStudyStepProps, useCaseStudyEditContext } from "./_CaseStudyEditContainer";
import { CaseStudyWorkflowManager } from "../../../managers/CaseStudyWorkflowManager";
import { LoadingComponent } from "../../../modules/core/LoadingComponent";
import moment from 'moment'
import { CaseStudyTagAutoComplete } from '../../../modules/case-studies/CaseStudyTagAutoComplete';
import { useGlobalContext } from '../../../modules/core/GlobalContext';
import { Tooltip } from '../../../modules/general/ToolTip';
import { Settings, Urls } from '../../../Constants';

export function CaseStudyEditTags(props: IEditCaseStudyStepProps) {

    const globalOnClick = (event: any) => {

        if (!event.target.closest(".addfield") && !event.target.closest(".addAdvertiser"))
            setAddAdvertiser(false)

        if (!event.target.closest(".addfield") && !event.target.closest(".addBrand"))
            setAddBrand(false)
    }

    const { getSetting } = useGlobalContext()
    const navigate = useNavigate();
    const { tags, countries, regions } = useGlobalContext();
    const { getApiConfiguration } = useAuthContext();

    var advertiserList = useRef<null | HTMLSelectElement>(null)
    var [advertiserValues, setAdvertiserValues] = useState<Array<TagValueInfo>>([])
    var [brandValues, setBrandValues] = useState<Array<TagValueInfo>>([])
    var [addAdvertiser, setAddAdvertiser] = useState(false)
    var [addBrand, setAddBrand] = useState(false)

    const { caseStudy, fetching } = useCaseStudyEditContext()

    useEffect(() => {
        setAdvertiserValues(tags?.advertisers?.values ?? [])
        setBrandValues(tags?.brands?.values ?? [])
        redrawMaterialize()
    }, [tags, countries, regions, fetching])

    async function addTag(tagValue: string, tagID?: number) {
        var apiConfig = getApiConfiguration();
        var tagApi = new TagsApi(apiConfig)
        var newTagValue = await tagApi.apiTagsValuePost({
            tagID: tagID,
            enabled: true,
            value: tagValue
        })
        return newTagValue;
    }

    async function redrawMaterialize() {
        setTimeout(() => {
            $('.materialize-textarea').each((index, item) => {
                M.textareaAutoResize(item)
                M.CharacterCounter.init(item)
            })
            $('select.materialize').each((index, item) => {
                M.FormSelect.init(item)
                var instance = M.FormSelect.getInstance(item);

                if(instance.el.classList.contains("invalid"))
                    instance.input.classList.add("invalid")
                else
                instance.input.classList.remove("invalid")

            })
        }, 100)
    }

    var initialValues = {
        id: 0,
        advertiser: caseStudy?.tags?.advertiser?.id,
        brand: caseStudy?.tags?.brand?.id,
        regions: caseStudy?.regions?.map(r => r.id),
        countries: caseStudy?.countries?.map(c => c.id),
        sectors: caseStudy?.tags?.sectors?.map(c => c.id),
        channels: caseStudy?.tags?.channels?.map(c => c.id),
        objectives: caseStudy?.tags?.objectives?.map(c => c.id),
        audiences: caseStudy?.tags?.audiences?.map(c => c.id),
        tools: caseStudy?.tags?.tools?.map(c => c.id),
        categories: caseStudy?.tags?.categories?.map(c => c.id),
        team: caseStudy?.fields?.team,
        implementationDate: caseStudy?.fields?.implementationDate,
        goodGrowth: caseStudy?.fields?.goodGrowth
    }

    async function submit(values: any, drafts: boolean) {
        values.id = caseStudy?.id ?? 0;

        var config = await getApiConfiguration();
        var workflowManager = new CaseStudyWorkflowManager(new CaseStudiesApi(config))
        var result = await workflowManager.saveCaseStudyTags(values)

        if (result?.id) {
            props.onComplete(CaseStudyStep.Tags, drafts)
        }
        else
            navigate("/")
    }

    return (
        (!fetching ?
            <div onClick={globalOnClick}>
                <div className="container bodycopy material">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={Yup.object().shape({
                            advertiser: Yup.string()
                                .required("Advertiser can't be blank"),
                            brand: Yup.string()
                                .required("Brand can't be blank"),
                            regions: Yup.array().min(1, "At least 1 region must be provided"),
                            countries: Yup.array().min(1, "At least 1 country must be provided"),
                            sectors: Yup.array().min(1, "At least 1 sector must be provided"),
                            channels: Yup.array().min(1, "At least 1 channel must be provided"),
                            objectives: Yup.array().min(1, "At least 1 objective must be provided"),
                            audiences: Yup.array().min(1, "At least 1 audience must be provided"),
                            tools: Yup.array().min(1, "At least 1 product must be provided"),
                            categories: Yup.array().min(1, "At least 1 category of work must be provided"),
                            implementationDate: Yup.string()
                                .required("An implementation date must be provided").nullable(),
                        })}
                        onSubmit={async (values) => {
                            await submit(values, false)
                        }}
                        validate={async () => {
                            setTimeout(redrawMaterialize, 1)
                            window.scrollTo(0, 0);
                            return true
                        }}
                        validateOnChange={false}
                        validateOnBlur={false}
                    >
                        {({ isSubmitting, handleChange, handleBlur, handleSubmit, isValid, getFieldProps, setFieldValue, errors, initialValues, values }) => (
                            <div>
                                {isSubmitting ? <LoadingComponent></LoadingComponent> :

                                    <form onSubmit={handleSubmit}>

                                        {CaseStudyHelpers.isTagsComplete(caseStudy) ?
                                            <div className="alert ok">
                                                <p>This section is complete</p>
                                            </div> :
                                            <div className="alert error">
                                                <p>This section is incomplete</p>
                                            </div>}

                                        {!isValid && (
                                            <div className="alert error">
                                                <ul>
                                                    {errors.advertiser && <p>{errors.advertiser}</p>}
                                                    {errors.brand && <p>{errors.brand}</p>}
                                                    {errors.regions && <p>{errors.regions}</p>}
                                                    {errors.countries && <p>{errors.countries}</p>}
                                                    {errors.implementationDate && <p>{errors.implementationDate}</p>}
                                                    {errors.sectors && <p>{errors.sectors}</p>}
                                                    {errors.channels && <p>{errors.channels}</p>}
                                                    {errors.objectives && <p>{errors.objectives}</p>}
                                                    {errors.audiences && <p>{errors.audiences}</p>}
                                                    {errors.tools && <p>{errors.tools}</p>}
                                                    {errors.categories && <p>{errors.categories}</p>}
                                                </ul>
                                            </div>
                                        )}

                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo withcreatelink">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyAdvertiserTooltip)}></Tooltip>
                                                <select ref={advertiserList} className={"advertiser-list materialize " + (errors.advertiser && "invalid")} {...getFieldProps('advertiser')}>
                                                    <option></option>
                                                    {advertiserValues?.map((tag) => {
                                                        return initialValues.advertiser == tag.id
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>

                                                <label>Advertiser</label>
                                                <a className="button no3d addAdvertiser" href="#" onClick={(e) => { e.preventDefault(); setAddAdvertiser(true) }}>Create New</a>
                                                {addAdvertiser ?
                                                    <CaseStudyTagAutoComplete key="addAdvertiser"
                                                        tagAdded={async (tagValue) => {
                                                            var newTag = await addTag(tagValue, tags?.advertisers?.id)
                                                            setAdvertiserValues(oldValues => [...oldValues, newTag]);
                                                            setAddAdvertiser(false);
                                                            setFieldValue("advertiser", newTag.id);
                                                            redrawMaterialize();

                                                        }}
                                                        tagSelected={(tagValueID) => {
                                                            setAddAdvertiser(false);
                                                            setFieldValue("advertiser", tagValueID);
                                                            redrawMaterialize();
                                                        }}
                                                        tags={tags?.advertisers?.values ?? []}></CaseStudyTagAutoComplete> : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo withcreatelink">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyBrandTooltip)}></Tooltip>
                                                <select className={"brand-list materialize " + (errors.brand && "invalid")} {...getFieldProps('brand')}>
                                                    <option></option>
                                                    {brandValues?.map((tag) => {
                                                        return initialValues.brand == tag.id
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Brand/Product</label>
                                                <a className="button no3d addBrand" href="#" onClick={(e) => { e.preventDefault(); setAddBrand(true) }}>Create New</a>
                                                {addBrand ?
                                                    <CaseStudyTagAutoComplete key="addBrand"
                                                        tagAdded={async (tagValue) => {
                                                            var newTag = await addTag(tagValue, tags?.brands?.id)
                                                            setBrandValues(oldValues => [...oldValues, newTag]);
                                                            setAddBrand(false);
                                                            setFieldValue("brand", newTag.id);
                                                            redrawMaterialize();

                                                        }}
                                                        tagSelected={(tagValueID) => {
                                                            setAddBrand(false);
                                                            setFieldValue("brand", tagValueID);
                                                            redrawMaterialize();
                                                        }}
                                                        tags={tags?.brands?.values ?? []}></CaseStudyTagAutoComplete> : ""
                                                }
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col m4 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyRegionTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.regions && "invalid")} multiple={true} {...getFieldProps('regions')}>
                                                    {regions.map((region) => {
                                                        {
                                                            return initialValues.regions?.includes(region.id)
                                                                ? (<option selected value={region.id}>{region.name}</option>) :
                                                                <option value={region.id}>{region.name}</option>
                                                        }
                                                    })}
                                                </select>
                                                <label>Region</label>
                                            </div>
                                            <div className="input-field col m4 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyCountryTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.countries && "invalid")} multiple={true} {...getFieldProps('countries')}>
                                                    {countries.map((country) => {
                                                        return initialValues.countries?.includes(country.id)
                                                            ? (<option selected value={country.id}>{country.name}</option>) :
                                                            <option value={country.id}>{country.name}</option>
                                                    })}
                                                </select>
                                                <label>Country</label>
                                            </div>
                                            <div className="input-field col m4 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyImplementationDateTooltip)}></Tooltip>
                                                <label htmlFor="implementationDate" className="datelabel">Date of implementation</label>
                                                <input
                                                    type="date"
                                                    className={(errors.implementationDate && "invalid")}
                                                    name="implementationDate"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    defaultValue={caseStudy?.fields?.implementationDate && moment(caseStudy?.fields?.implementationDate).format("YYYY-MM-DD")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudySectorTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.sectors && "invalid")} multiple={true} {...getFieldProps('sectors')}>
                                                    {tags?.sectors?.values?.map((tag) => {
                                                        return initialValues.sectors?.includes(tag.id)
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Industry Sector</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyChannelTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.channels && "invalid")} multiple={true} {...getFieldProps('channels')}>
                                                    {tags?.channels?.values?.map((tag) => {
                                                        return initialValues.channels?.includes(tag.id)
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Channel</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyObjectiveTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.objectives && "invalid")} multiple={true} {...getFieldProps('objectives')}>
                                                    {tags?.objectives?.values?.map((tag) => {
                                                        return initialValues.objectives?.includes(tag.id)
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Marketing Objective</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyAudienceTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.audiences && "invalid")} multiple={true} {...getFieldProps('audiences')}>
                                                    {tags?.audiences?.values?.map((tag) => {
                                                        return initialValues.audiences?.includes(tag.id)
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Target Audience</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyToolsTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.tools && "invalid")} multiple={true} {...getFieldProps('tools')}>
                                                    {tags?.tools?.values?.map((tag) => {
                                                        return initialValues.tools?.includes(tag.id)
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Proprietary Products Used</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyTeamTooltip)}></Tooltip>
                                                <textarea {...getFieldProps('team')} className="materialize-textarea validate" rows={5} data-length="700" maxLength={700} />
                                                <label className="active">Mindshare Team</label>
                                            </div>
                                        </div>
                                        <div className="row clearfix">
                                            <div className="input-field col s12 withinfo">
                                                <Tooltip tooltip={getSetting(Settings.CaseStudyCategoriesTooltip)}></Tooltip>
                                                <select className={"materialize " + (errors.categories && "invalid")} multiple={true} {...getFieldProps('categories')}>
                                                    {tags?.categories?.values?.map((tag) => {
                                                        return initialValues.categories?.includes(tag.id)
                                                            ? (<option selected value={tag.id}>{tag.value}</option>) :
                                                            <option value={tag.id}>{tag.value}</option>
                                                    })}
                                                </select>
                                                <label>Category of Work</label>
                                                <p className="expl" dangerouslySetInnerHTML={{__html: getSetting(Settings.CaseStudyCategoriesDescription)}}></p>
                                            </div>
                                        </div>
                                        <div className="clearfix button-block">
                                            <button disabled={isSubmitting} type="submit" id="proceed" className="button right no3d">
                                                Save and Continue &nbsp; &gt;
                                            </button>
                                            {!caseStudy?.published &&
                                                <button type="submit" id="drafts" onClick={(() => submit(values, true))} className="back-button" name="drafts" formNoValidate>Save to drafts</button>
                                            }
                                        </div>
                                    </form>}
                            </div>
                        )}
                    </Formik>
                </div>
            </div >

            : <LoadingComponent loadingAnimationDelay={0}></LoadingComponent>)
    )
}


